<template>
  <div class="app__footer">
    <div class="wrapper">
      <p>&copy; <span id="year">{{ new Date().getFullYear() }}</span> Visos teisės saugomos, LLA</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appFooter',
};
</script>
