<template>
  <div>
    <Header />
    <div class="app__content">
      <main>
        <Nav />
          <router-view :key="$route.path">
          </router-view>
        <SecondaryNav />
        <Sponsors :apiUrl = "apiUrl" />
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>

import Header from './components/Header.vue';
import Nav from './components/Nav.vue';
import SecondaryNav from './components/SecondaryNav.vue';
import Sponsors from './components/Sponsors.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header, Nav, SecondaryNav, Sponsors, Footer,
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
};

</script>

<style>
  @import './assets/css/bootstrap.min.css';
  @import './assets/css/main.css';
</style>
