import { createRouter, createWebHistory } from 'vue-router';
import Pagrindinis from '../views/Pagrindinis.vue';
import Apie from '../views/Apie.vue';
import Istorija from '../views/Istorija.vue';
import Kontaktai from '../views/Kontaktai.vue';
import Kalendorius from '../views/Kalendorius.vue';
import Naujienos from '../views/Naujienos.vue';
import Renginys from '../views/Renginys.vue';
import Naujiena from '../views/Naujiena.vue';
import Galerija from '../views/Galerija.vue';
import Rekomenduojamos from '../views/Rekomenduojamos.vue';
import Rekomendacija from '../views/Rekomendacija.vue';
import Klaida from '../views/Klaida.vue';

const routes = [
  {
    path: '/',
    name: 'pagrindinis',
    component: Pagrindinis,
  },
  {
    path: '/apie',
    name: 'apie',
    component: Apie,
  },
  {
    path: '/istorija',
    name: 'istorija',
    component: Istorija,
  },
  {
    path: '/kontaktai',
    name: 'kontaktai',
    component: Kontaktai,
  },
  {
    path: '/kas-vyksta',
    name: 'kalendorius',
    component: Kalendorius,
  },
  {
    path: '/kas-vyksta/:id',
    name: 'renginys',
    component: Renginys,
  },
  {
    path: '/naujienos',
    name: 'naujienos',
    component: Naujienos,
  },
  {
    path: '/naujienos/:id',
    name: 'naujiena',
    component: Naujiena,
  },
  {
    path: '/galerija',
    name: 'galerija',
    component: Galerija,
  },
  {
    path: '/leidejai-rekomenduoja',
    name: 'rekomenduojamos',
    component: Rekomenduojamos,
  },
  {
    path: '/rekomendacijos/:id',
    name: 'rekomendacija',
    component: Rekomendacija,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'klaida',
    component: Klaida,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0, behavior: 'smooth' };
  },
});

export default router;
