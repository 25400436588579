import { createApp } from 'vue';
import VueLazyloadNext from 'vue-lazyload-next';
import App from './App.vue';
import router from './router';

createApp(App)
  .use(router)
  .use(VueLazyloadNext, {
    preLoad: 1.3,
    error: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1' %3E%3C/svg%3E",
    loading: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1' %3E%3C/svg%3E",
    attempt: 1,
  })
  .mount('#app');
