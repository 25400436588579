/* eslint-disable */
<template>
  <section class="sponsors-list">
    <div class="wrapper">
      <div v-for='item in responseData' :key="item.id" class='placeholder-div'>
        <h2>{{ item.pavadinimas }}</h2>
        <ul>
          <li v-for='subitem in item.remejo_logo_su_nuoroda' :key="subitem.id">
            <a :href="subitem.remejo_nuoroda" target="_blank">
              <img class='remejai-img'
              v-lazy='apiUrl + subitem.remejo_logotipas.data.attributes.url'
                :data-srcset='srcsetUrl(apiUrl, subitem.remejo_logotipas.data.attributes)'
                :alt='subitem.remejo_logotipas.data.attributes.alternativeText
                  || subitem.remejo_logotipas.data.attributes.name'>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from 'vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';

export default {
  name: 'appSponsors',
  props: {
    apiUrl: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const responseData = ref('');
    const fetchUrl = '/api/remejai?populate[2]=remejas.remejo_logo_su_nuoroda.remejo_logotipas';

    const fetchData = async (url, config) => {
      const response = await fetch(`${props.apiUrl}${url}`, config).then(checkStatus).then(parseJSON);
      responseData.value = response.data.attributes.remejas;
    };

    onMounted(() => {
      fetchData(fetchUrl, fetchConfig);
    });

    return { responseData, srcsetUrl };
  },
};
</script>
