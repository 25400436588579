<template>
  <div class='container'>
    <div class='error-page'>
      <div class='error'>404</div>
      <div class='message'>Gaila :( Šis puslapis yra nepasiekiamas...</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'viewKlaida',
};
</script>
