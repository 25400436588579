<template>
  <div class='naujiena-container'>
    <div class="left-column">
      <div
        v-for='(item, index) in naujienos'
        :key="item.id">
        <div
          v-if="index % 2 === 0"
          class="naujiena-wrapper"
        >
            <div class="image">
              <img
                  v-lazy='apiUrl + item.attributes.image.data.attributes.url'
                  :data-srcset="srcsetUrl(apiUrl, item.attributes.image.data.attributes)"
                  :alt='item.attributes.image.data.attributes.alternativeText
                      || item.attributes.image.data.attributes.name'
              >
            </div>
            <div class="info-wrapper">
              <h2 class="title">{{ item.attributes.pavadinimas }}</h2>
              <div v-if="item.attributes.istrauka" class="description">
                <span>{{ item.attributes.istrauka }}</span>
              </div>
              <router-link
                :to='{
                  name: "rekomendacija",
                  params: {
                    id: item.attributes.slug,
                  },
                }'
                class="link"
              >
                {{ item.attributes.mygtuko_tekstas || 'Skaityti daugiau...'}}
              </router-link>
            </div>
        </div>
      </div>
    </div>
    <div class="right-column">
      <div
        v-for='(item, index) in naujienos'
        :key="item.id">
        <div
          v-if="index % 2 !== 0"
          class="naujiena-wrapper"
        >
            <div class="image">
              <img
                  v-lazy='apiUrl + item.attributes.image.data.attributes.url'
                  :data-srcset="srcsetUrl(apiUrl, item.attributes.image.data.attributes)"
                  :alt='item.attributes.image.data.attributes.alternativeText
                      || item.attributes.image.data.attributes.name'
              >
            </div>
            <div class="info-wrapper">
              <h2 class="title">{{ item.attributes.pavadinimas }}</h2>
              <div v-if="item.attributes.istrauka" class="description">
                <span>{{ item.attributes.istrauka }}</span>
              </div>
              <router-link
                :to='{
                  name: "rekomendacija",
                  params: {
                    id: item.attributes.slug,
                  },
                }'
                class="link"
              >
                {{ item.attributes.mygtuko_tekstas || 'Skaityti daugiau...'}}
              </router-link>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref, onMounted } from 'vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';

export default {
  name: 'appRekomendacijos',
  setup() {
    const naujienos = ref([]);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;
    const fetchUrl = '/api/rekomendacijos?populate=*&pagination[page]=1&pagination[pageSize]=100';

    const fetchData = async (url, config) => {
      const response = await fetch(`${apiUrl}${url}`, config).then(checkStatus).then(parseJSON);
      naujienos.value = response.data;
    };

    onMounted(() => {
      fetchData(fetchUrl, fetchConfig);
    });

    return {
      naujienos,
      apiUrl,
      srcsetUrl,
    };
  },
};
</script>

<style scoped lang="sass">
  .naujiena-container
    display: flex
    justify-content: space-between

    @media (max-width: 767px)
      flex-direction: column

    > .left-column, > .right-column
      flex: 1 1 calc(50% - 20px)
      width: 100%
      max-width: 450px

      @media (max-width: 767px)
        flex: 1 1 100%
        max-width: 100%

    > .left-column
      margin-right: 10px

      @media (max-width: 767px)
        margin-right: 0

    > .right-column
      margin-left: 10px

      @media (max-width: 767px)
        margin-left: 0

  .naujiena-wrapper
    display: block
    overflow: hidden
    margin-bottom: 30px

    @media (max-width: 767px)
      margin-bottom: 70px

    @media (max-width: 500px)
      margin-bottom: 50px

    > .image img
        width: 100%

    > .info-wrapper
      background-color: rgba(106,157,202,0.5)
      font-weight: 500
      line-height: 1.5
      height: 100%

      > .time
        display: block
        padding: 20px
        background-color: rgba(17,33,98,0.5)
        color: white

      > .title
        padding: 20px
        font-size: 24px
        font-weight: 600
        color: #111

      > .description
        padding: 20px
        color: #333

      > .link
        margin: 20px
        padding: 6px 25px
        display: inline-block
        border: 2px solid rgba(17,33,98,1)
        border-radius: 20px
        transition: all .3s ease
        color: #333

        &:hover
          background-color: rgba(17,33,98,1)
          text-decoration: none
          color: white

</style>
