<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="container --container-realignment">
    <div class="info-text">
      <div class="info--heading">
        <h1>{{ apie.pavadinimas }}</h1>
      </div>
      <div class="info-text-block --indent" v-if='apie.tekstas' v-html='apie.tekstas'></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Klaida from './Klaida.vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';

export default {
  name: 'viewApie',
  components: {
    Klaida,
  },
  setup() {
    const apie = ref('');
    const error = ref(false);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;

    const fetchData = async (config) => {
      try {
        const response = await fetch(`${apiUrl}/api/apie`, config).then(checkStatus).then(parseJSON);
        apie.value = response.data.attributes;
      } catch (err) {
        error.value = true;
      }
    };

    onMounted(() => {
      fetchData(fetchConfig);
    });

    return {
      apie,
      error,
    };
  },
};
</script>
