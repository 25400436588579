<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="container --container-realignment">
    <div class="about_ltu-text">
      <div class="info--heading">
        <h1>{{ pavadinimas }}</h1>
      </div>
      <div class="about_ltu--info">
        <section class="info-text-block" v-for='item in antrasteMedia' :key="item.id">
          <h2>{{ item.antraste }}</h2>
          <div class="events-wrapper gallery">
            <vue-chocolat class='event-lists'>
            <a v-for='image in item.nuotraukos.data'
               :key='image.id'
               :href='apiUrl + image.attributes.url'
               class='gallery-link'
            >
              <div class="one-event-block">
                <div class="one-event-image">
                  <img v-lazy='apiUrl + image.attributes.url'
                    :data-srcset="srcsetUrl(apiUrl, image.attributes)"
                    :alt='image.attributes.alternativeText || image.attributes.name'>
                </div>
              </div>
            </a>
            </vue-chocolat>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import vueChocolat from 'vue-chocolat';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';
import Klaida from './Klaida.vue';

export default {
  name: 'viewGalerija',
  components: {
    Klaida,
    'vue-chocolat': vueChocolat,
  },
  setup() {
    const pavadinimas = ref('');
    const antrasteMedia = ref([]);
    const error = ref(false);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;
    const fetchUrl = '/api/galerija?populate=antraste_media.nuotraukos';

    const fetchData = async (url, config) => {
      try {
        const response = await fetch(`${apiUrl}${url}`, config).then(checkStatus).then(parseJSON);
        pavadinimas.value = response.data.attributes.pavadinimas;
        antrasteMedia.value = response.data.attributes.antraste_media;
      } catch (err) {
        error.value = true;
      }
    };

    onMounted(() => {
      fetchData(fetchUrl, fetchConfig);
    });

    return {
      antrasteMedia,
      pavadinimas,
      error,
      apiUrl,
      srcsetUrl,
    };
  },
};
</script>

<style scoped>
  @import '../assets/css/events.css';
</style>
