<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="container --container-realignment naujiena-page">
    <h1 class="heading">{{ naujiena.pavadinimas }}</h1>
    <time
      class="time"
      :datetime="naujiena.data_laikas"
    >
      {{ naujiena.data }}
    </time>
    <div class="image">
      <img
        v-lazy='apiUrl + naujienosNuotrauka.url'
        :data-srcset="srcsetUrl(apiUrl, naujienosNuotrauka)"
        :alt='naujienosNuotrauka.alternativeText || naujienosNuotrauka.name'
      >
    </div>
    <div
      class="main-info"
      v-html='naujiena.informacija'>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import Klaida from './Klaida.vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';

export default {
  name: 'viewNaujiena',
  components: {
    Klaida,
  },
  setup() {
    const naujiena = ref('');
    const naujienosNuotrauka = ref('');
    const error = ref(false);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;
    const route = useRoute();
    const fetchUrl = `/api/naujienos/?filters[slug][$eq]=${route.params.id}`;

    const fetchData = async (config) => {
      const initRequest = await fetch(`${apiUrl}${fetchUrl}`, config).then(checkStatus).then(parseJSON);
      const link = initRequest.data[0].id;
      try {
        const response = await fetch(`${apiUrl}/api/naujienos/${link}?populate=image`, config).then(checkStatus).then(parseJSON);
        naujiena.value = response.data.attributes;
        naujienosNuotrauka.value = response.data.attributes.image.data.attributes;
      } catch (err) {
        error.value = true;
      }
    };

    onMounted(() => {
      fetchData(fetchConfig);
    });

    return {
      naujiena,
      naujienosNuotrauka,
      error,
      apiUrl,
      srcsetUrl,
    };
  },
};
</script>

<style scoped lang="sass">
.naujiena-page
  overflow: hidden

  > .time
    display: inline-block
    margin-bottom: 30px

  > .image
    overflow: hidden
    margin-bottom: 50px

    img
      max-width: 100%
      width: auto
      max-height: 600px
      height: auto

figure.image img
  max-width: 500px
  max-height: 500px
  width: 100%
  margin: 30px auto
  object-fit: contain
</style>
