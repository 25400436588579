<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="container --container-realignment">
    <div class="info--heading">
      <h1>{{ pavadinimas }}</h1>
    </div>
    <div class="naujienos-wrapper">
      <Rekomendacijos />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';
import Klaida from './Klaida.vue';
import Rekomendacijos from '../components/Rekomendacijos.vue';

export default {
  name: 'viewRekomenduojamos',
  components: {
    Klaida,
    Rekomendacijos,
  },
  setup() {
    const pavadinimas = ref('');
    const error = ref(false);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;
    const fetchUrl = '/api/leidejai-rekomenduoja';

    const fetchData = async (url, config) => {
      try {
        const response = await fetch(`${apiUrl}${url}`, config).then(checkStatus).then(parseJSON);
        pavadinimas.value = response.data.attributes.pavadinimas;
      } catch (err) {
        error.value = true;
      }
    };

    onMounted(() => {
      fetchData(fetchUrl, fetchConfig);
    });

    return {
      pavadinimas,
      error,
      apiUrl,
      srcsetUrl,
    };
  },
};
</script>
