<template>
  <div class='event-lists'>
    <router-link
      v-for='item in renginiai'
      :to='{
        name: "renginys",
        params: {
          id: item.attributes.slug,
        },
      }'
      :key="item.id"
    >
      <div class="one-event-block">
        <div class="one-event-image">
          <img
              v-lazy='apiUrl + item.attributes.renginio_nuotrauka.data.attributes.url'
              :data-srcset="srcsetUrl(apiUrl, item.attributes.renginio_nuotrauka.data.attributes)"
              :alt='item.attributes.renginio_nuotrauka.data.attributes.alternativeText
                  || item.attributes.renginio_nuotrauka.data.attributes.name'
          >
        </div>
        <div class="one-event-info-wrapper">
          <div class="one-event-time">
            <span>{{ item.attributes.data }}</span>
          </div>
          <div class="one-event-name">
            <span>{{ item.attributes.renginio_pavadinimas }}</span>
          </div>
          <div class="one-event-location">
            <span>{{ item.attributes.miestas }}</span>
          </div>
        </div>

      </div>
    </router-link>
  </div>
</template>

<script>

import { ref, onMounted } from 'vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';

export default {
  name: 'appRenginiai',
  setup() {
    const renginiai = ref([]);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;
    const fetchUrl = '/api/renginiais?populate[1]=renginio_nuotrauka';

    const fetchData = async (url, config) => {
      const response = await fetch(`${apiUrl}${url}`, config).then(checkStatus).then(parseJSON);
      renginiai.value = response.data;
    };

    onMounted(() => {
      fetchData(fetchUrl, fetchConfig);
    });

    return {
      renginiai,
      apiUrl,
      srcsetUrl,
    };
  },
};
</script>
