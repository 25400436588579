/* eslint-disable no-param-reassign */
export const parseJSON = (resp) => (resp.json ? resp.json() : resp);

export const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then(() => {
    throw resp;
  });
};

export const fetchConfig = () => ({
  method: 'GET',
  headers: { 'Content-Type': 'application/json' },
});
