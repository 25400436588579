<template>
  <nav class='main-nav container'>
    <div
      class='full-overlay'
      :class='{ opened: isOpened }'
      @click='clickNav'
      @keypress="clickNav"
    ></div>
    <div
      class='label'
      :class='{ opened: isOpened }'
      @click='clickNav'
      @keypress="clickNav"
    >
      <span />
      <span />
      <span />
    </div>
    <ul class='navigation-list'>
      <li class='item' @click='clickNav' @keypress="clickNav">
        <router-link :to="{ name: 'kalendorius' }" class="nav-button">
          <img
            width="90"
            height="90"
            class='nav-icon'
            src='@/assets/images/ikonos/kas-vyksta.png'
            alt='lietuvaskaito kas vyksta ikona'
          >
          <div>KAS VYKSTA</div>
        </router-link>
      </li>
      <li class='item' @click='clickNav' @keypress="clickNav">
        <router-link :to="{ name: 'naujienos' }" class="nav-button">
          <img
            width="90"
            height="90"
            class='nav-icon'
            src='@/assets/images/ikonos/naujienos.png'
            alt='lietuvaskaito naujienos ikona'
          >
          <div>NAUJIENOS</div>
        </router-link>
      </li>
      <li class='item' @click='clickNav' @keypress="clickNav">
        <router-link :to="{ name: 'galerija' }" class="nav-button">
          <img
            width="90"
            height="90"
            class='nav-icon'
            src='@/assets/images/ikonos/galerija.png'
            alt='lietuvaskaito galerija ikona'
          >
          <div>GALERIJA</div>
        </router-link>
      </li>
      <li class='item' @click='clickNav' @keypress="clickNav">
        <router-link :to="{ name: 'rekomenduojamos' }" class="nav-button">
          <img
            width="90"
            height="90"
            class='nav-icon'
            src='@/assets/images/ikonos/rekomenduojamos.png'
            alt='lietuvaskaito leidėjai rekomenduoja ikona'
          >
          <div>LEIDĖJAI REKOMENDUOJA</div>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'appNav',
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    clickNav() {
      const mediaQuery = window.matchMedia('(max-width: 770px)');
      if (mediaQuery.matches) {
        this.isOpened = !this.isOpened;
        document.body.classList.toggle('fixed');
      }
    },
  },
};
</script>
