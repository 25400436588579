<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="event--container --container-realignment">
    <div class="event--image">
      <img
        v-lazy='apiUrl + renginioNuotrauka.url'
        :data-srcset="srcsetUrl(apiUrl, renginioNuotrauka)"
        :alt='renginioNuotrauka.alternativeText || renginioNuotrauka.name'
      >
    </div>
    <div class="event-text">
      <div class="event--name">
        <h1>{{ renginys.renginio_pavadinimas }}</h1>
      </div>
      <div class="event--info">
        <div class="event-text-block">
          <h2>Informacija</h2>
        </div>
        <div class="event-info-block">
          <p>
            <b>Vieta</b>: {{ renginys.vieta }}
          </p>
          <p>
            <b>Miestas</b>: {{ renginys.miestas }}
          </p>
          <p>
            <b>Renginio data</b>: {{ renginys.data }}
          </p>
        </div>

        <div
          class="event-text-block"
          v-if='renginys.papildoma_informacija' v-html='renginys.papildoma_informacija'>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
import Klaida from './Klaida.vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';

export default {
  name: 'viewRenginys',
  components: {
    Klaida,
  },
  setup() {
    const renginys = ref('');
    const renginioNuotrauka = ref('');
    const error = ref(false);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;
    const route = useRoute();
    const fetchUrl = `/api/renginiais/?filters[slug][$eq]=${route.params.id}`;

    const fetchData = async (config) => {
      const initRequest = await fetch(`${apiUrl}${fetchUrl}`, config).then(checkStatus).then(parseJSON);
      const link = initRequest.data[0].id;
      try {
        const response = await fetch(`${apiUrl}/api/renginiais/${link}?populate=renginio_nuotrauka`, config).then(checkStatus).then(parseJSON);
        renginys.value = response.data.attributes;
        renginioNuotrauka.value = response.data.attributes.renginio_nuotrauka.data.attributes;
      } catch (err) {
        error.value = true;
      }
    };

    onMounted(() => {
      fetchData(fetchConfig);
    });

    return {
      renginys,
      renginioNuotrauka,
      error,
      apiUrl,
      srcsetUrl,
    };
  },
};
</script>

<style scoped>
  @import '../assets/css/event.css';
</style>
