<template>
  <div v-if="error" class='main-gallery'>
    <Klaida />
  </div>
  <section v-else class='main-gallery'>
    <div class="wrapper">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        navigation
      >
        <swiper-slide v-for='image in karusele' :key='image.id'>
          <div class="img-box">
            <div class="img-padding">
              <div class="img-wrapper">
                <img v-lazy='apiUrl + image.url'
                  :data-srcset="srcsetUrl(apiUrl, image.attributes)"
                  :alt='image.attributes.alternativeText
                  || image.attributes.name'
                >
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="subcarousel-text" v-if='tekstas' v-html='tekstas'></div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  Navigation, A11y,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/a11y';
import { ref, onMounted } from 'vue';
import Klaida from './Klaida.vue';
import { checkStatus, parseJSON, fetchConfig } from '../assets/js/helpers/fetchRequestHelpers';
import srcsetUrl from '../assets/js/helpers/computeImgUrl';

export default {
  name: 'viewPagrindinis',
  components: {
    Klaida,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const karusele = ref([]);
    const tekstas = ref('');
    const error = ref(false);

    const apiUrl = process.env.VUE_APP_STRAPI_API_URL;
    const fetchUrl = '/api/pagrindinis?populate[1]=karusele';

    const fetchData = async (url, config) => {
      try {
        const response = await fetch(`${apiUrl}${url}`, config).then(checkStatus).then(parseJSON);
        karusele.value = response.data.attributes.karusele.data;
        tekstas.value = response.data.attributes.tekstas;
      } catch (err) {
        error.value = true;
      }
    };

    onMounted(() => {
      fetchData(fetchUrl, fetchConfig);
    });

    return {
      modules: [Navigation, A11y],
      karusele,
      tekstas,
      error,
      apiUrl,
      srcsetUrl,
    };
  },
};
</script>
